import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useWindowMessure } from "../../hooks/useWindowMessure";
import "./index.scss";

function WorkShop() {
  const [width, height] = useWindowMessure();
  const [messure, setMessure] = useState({ width, height });

  useEffect(() => {
    let newWidth = 0;
    let newHeight = 0;
    const widthPercent = 2.2;
    const heightPercent = 1.5;
    if (width <= height) {
      newWidth = height * widthPercent;
      newHeight = height * heightPercent;
    } else if (width > height * widthPercent) {
      const percent = (height * 100) / width;
      if (percent > 60) {
        newWidth = width;
        newHeight = height * heightPercent;
      } else {
        newWidth = width;
        newHeight = ((width * 60) / 100) * heightPercent;
      }
    } else {
      newWidth = width * heightPercent;
      newHeight = newWidth * 1.1 * heightPercent;
    }

    setMessure({
      width: newWidth,
      height: newHeight,
    });
  }, [width, height]);

  return (
    <div className="container">
      <div className="videoContainer">
        <iframe
          className="videoFrame"
          title="video"
          src="https://player.vimeo.com/video/394650168?muted=1&autoplay=1&loop=1&autopause=0&background=1&title=0&byline=0&portrait=0"
          style={{
            width: `${messure.width}px`,
            height: `${messure.height}px`,
          }}
          frameBorder="0"
        ></iframe>
      </div>
      <Header></Header>
      <div className="workshopContainer">
        <div className="workshopText">
          <p className="title">Workshops - Talks</p>
        </div>
        <div className="workshopContent">
          <div className="workshopItem">
            <a
              rel="noreferrer"
              href="https://maca.aq.upm.es/quien/yannet-vilela/"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                ETSAM Madrid - Spain November 2020
              </div>
              <img src="imgs/workshops/etsamMadrid.jpg" alt="etsamMadrid"></img>
            </a>
          </div>
          <div className="workshopItem">
            <a
              rel="noreferrer"
              href="https://www.descubrelima.pe/cultura-desde-casa_/artes-visuales/mapping-y-exposiciones-virtuales-desde-casa/?fbclid=IwAR3wui5S7UX0LI4ssSbzlLpx8WkyRKlBXPTM8yn1Gn-JHYDepGLHsEVUF2U"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                "Videomapping desde casa" – Municipalidad de Lima – July 2020
                (LIMA)
              </div>
              <img src="imgs/workshops/muniLima.png" alt="muniLima"></img>
            </a>
          </div>
          <div className="workshopItem">
            <div className="item">
              <div className="titleItem">
                #quarantinetalks: buscando nuevas alternativas a la creación en
                el campo de la tecnologia - Chola Mag
              </div>
              <img
                src="imgs/workshops/quarantineTalks.jpg"
                alt="quarantineTalks"
              ></img>
            </div>
          </div>
          <div className="workshopItem">
            <a
              rel="noreferrer"
              href="https://www.facebook.com/n3t4n3t4/videos/356946421755513"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Video mapping workshop / Residency Kai – September 2018 (Cusco)
              </div>
              <img src="imgs/workshops/resiKai.jpg" alt="resiKai"></img>
            </a>
          </div>
          <div className="workshopItem">
            <a
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=Kt7nOkYGkyk"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Workshop “Dibujos y video mapping” Festival Lamas Ciudad Mural -
                October 2018
              </div>
              <img src="imgs/workshops/waykishungo.jpg" alt="waykishungo"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkShop;
