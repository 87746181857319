import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useWindowMessure } from "../../hooks/useWindowMessure";
import "./index.scss";

function Exhibition() {
  const [width, height] = useWindowMessure();
  const [messure, setMessure] = useState({ width, height });

  useEffect(() => {
    let newWidth = 0;
    let newHeight = 0;
    const widthPercent = 2.2;
    const heightPercent = 1.5;
    if (width <= height) {
      newWidth = height * widthPercent;
      newHeight = height * heightPercent;
    } else if (width > height * widthPercent) {
      const percent = (height * 100) / width;
      if (percent > 60) {
        newWidth = width;
        newHeight = height * heightPercent;
      } else {
        newWidth = width;
        newHeight = ((width * 60) / 100) * heightPercent;
      }
    } else {
      newWidth = width * heightPercent;
      newHeight = newWidth * 1.1 * heightPercent;
    }

    setMessure({
      width: newWidth,
      height: newHeight,
    });
  }, [width, height]);

  return (
    <div className="container">
      <div className="videoContainer">
        <iframe
          className="videoFrame"
          title="video"
          src="https://player.vimeo.com/video/494719755?muted=1&autoplay=1&loop=1&autopause=0&background=1&title=0&byline=0&portrait=0"
          style={{
            width: `${messure.width}px`,
            height: `${messure.height}px`,
          }}
          frameBorder="0"
        ></iframe>
      </div>
      <Header></Header>
      <div className="exhibitionContainer">
        <div className="exhibitionText">
          <p className="title">Exhibitions - Festivals - Participations</p>
        </div>
        <div className="exhibitionContent">
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=CkNJACnjmbY&feature=youtu.be"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Miyashita Park by Neo Shibuya + New Media Art - Japan December
                2020
              </div>
              <img src="imgs/exhibitions/neoShibuya.jpg" alt="neoShibuya"></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://nexus.mutek.us/"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Mutek SF Online (Glitch Party AV) – May 2020
              </div>
              <img src="imgs/exhibitions/mutek.jpg" alt="mutek"></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=1SKuSC7LIAg&t=878s"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Red Planet VR party + visual exhibit streaming on Currents.FM -
                October 2020
              </div>
              <img src="imgs/exhibitions/redPlanet.png" alt="redPlanet"></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://www.digitalartmonth.com/exhibitors-1?itemId=p9f55k90t3938tltxwmw4yf98rpgmh"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                AR Filters “Spinoso + Binary error” for Digital Art Month by
                CADAF ART – Miami December 2020
              </div>
              <img src="imgs/exhibitions/digitalAM.png" alt="digitalAM"></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=bQS6K3Ybddk&t=782s"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Visuals for Isolation Mixtape by Footwork Jungle - Worldwide
                -December 2020
              </div>
              <img
                src="imgs/exhibitions/ISOLATION-TAPE-RELEASE-PARTY.jpg"
                alt="ISOTAPEstream_N3T4"
              ></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://www.facebook.com/2097853750342928/videos/794234297773083"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Live virtual performances Glitch AV - Algo Ritmi / Italy – May
                2020
              </div>
              <img src="imgs/exhibitions/algoRitmi.jpg" alt="algoRitmi"></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://vimeo.com/394650168"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Camino hacia el fractal - Artmadrid - Spain - February 2020
              </div>
              <img src="imgs/exhibitions/artMadrid.png" alt="artMadrid"></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://vimeo.com/483696549"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Creative code festival 2020 (Red Storm AV) / Lightbox New York –
                November 2020
              </div>
              <img src="imgs/exhibitions/redStrom.png" alt="redStrom"></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=FTJV6BXJJUs&feature=youtu.be"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                NKB + CREATIVE CODE VISUAL ART SHOWCASE - August 2020
              </div>
              <img src="imgs/exhibitions/nkb.png" alt="nkb"></img>
            </a>
          </div>
          <div className="exhibitionItem">
            <a
              rel="noreferrer"
              href="https://www.facebook.com/MostraTuaArte/photos/a.120003559693750/168067471554025/"
              target="_blank"
              className="item"
            >
              <div className="titleItem">
                Visuals on quarantine / Mostra tu arte Brasil September 2020
              </div>
              <img src="imgs/exhibitions/mostraarte.jpg" alt="mostraarte"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exhibition;
