import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useWindowMessure } from "../../hooks/useWindowMessure";
import "./index.scss";

function About() {
  const [width, height] = useWindowMessure();
  const [messure, setMessure] = useState({ width, height });

  useEffect(() => {
    let newWidth = 0;
    let newHeight = 0;
    const widthPercent = 2.2;
    const heightPercent = 1.5;
    if (width <= height) {
      newWidth = height * widthPercent;
      newHeight = height * heightPercent;
    } else if (width > height * widthPercent) {
      const percent = (height * 100) / width;
      if (percent > 60) {
        newWidth = width;
        newHeight = height * heightPercent;
      } else {
        newWidth = width;
        newHeight = ((width * 60) / 100) * heightPercent;
      }
    } else {
      newWidth = width * heightPercent;
      newHeight = newWidth * 1.1 * heightPercent;
    }

    setMessure({
      width: newWidth,
      height: newHeight,
    });
  }, [width, height]);

  return (
    <div className="container">
      <div className="videoContainer">
        <iframe
          className="videoFrame"
          title="video"
          src="https://player.vimeo.com/video/494612261?muted=1&autoplay=1&loop=1&autopause=0&background=1&title=0&byline=0&portrait=0"
          style={{
            width: `${messure.width}px`,
            height: `${messure.height}px`,
          }}
          frameBorder="0"
        ></iframe>
      </div>
      <Header></Header>
      <div className="aboutContainer">
        <div className="aboutContent">
          <div className="aboutText">
            <p className="title">ABOUT ME</p>
            <p className="text">
              I'm Yannet Vilela (N3t4), New Media Artist from Peru
            </p>
            <div className="skillsContainer">
              <p className="subtittle">SKILLS</p>
              <div className="skillsContent">
                <div className="skillsItem">
                  <p>Experimental video art</p>
                </div>
                <div className="skillsItem">
                  <p>Digital experiences</p>
                </div>
                <div className="skillsItem">
                  <p>Video mapping</p>
                </div>
                <div className="skillsItem">
                  <p>AR</p>
                </div>
                <div className="skillsItem">
                  <p>3D art</p>
                </div>
                <div className="skillsItem">
                  <p>VJ sets</p>
                </div>
                <div className="skillsItem">
                  <p>Design for VR environments & videogames</p>
                </div>
              </div>
            </div>
            {/* <div className="interviewContainer">
              <p className="subtittle">INTERVIEWS</p>
              <div className="interviewContent">
                <div className="interviewItem">
                  <p>Audiodrome 09: N3t4 / Point of View (MEX) </p>
                  <a
                    href="http://pov.com.mx/audiodrome-09-n3t4/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://pov.com.mx/audiodrome-09-n3t4
                  </a>
                </div>
                <div className="interviewItem">
                  <p>Clubbing 3.0 con N3t4 / Chola Magazine (PE) </p>
                  <a
                    href="https://cholamagazine.com/2020/05/08/clubbing-3-0-con-n3t4/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://cholamagazine.com/2020/05/08/clubbing-3-0-con-n3t4
                  </a>
                </div>
                <div className="interviewItem">
                  <p>Lunario interview (PE)</p>
                  <a
                    href="https://lunario.pe/n3t4entrevista/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://lunario.pe/n3t4entrevista/
                  </a>
                </div>
              </div>
            </div> */}
            <div className="redesContainer">
              <div className="redesTitle">Contact me</div>
              <div className="redesIconContainer">
                <a
                  href="https://www.facebook.com/n3t4n3t4"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-square redesIcon"></i>
                </a>
                <a
                  href="https://www.instagram.com/n3t4_/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram redesIcon"></i>
                </a>
                <a
                  href="https://soundcloud.com/n3t4"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-soundcloud redesIcon"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCUBzTMOTrEWhhwLV25ZaIBw"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube redesIcon"></i>
                </a>
                <a
                  href="https://vimeo.com/n3t4"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-vimeo-v redesIcon"></i>
                </a>
              </div>
              <div className="redesMail">
                <i className="fas fa-envelope redesIcon"></i>
                <span>yannet.vm@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
