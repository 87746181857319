import { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import UnityProgress from "./TemplateData/UnityProgress";
import "./index.scss";

function Game() {
  let unityRef = useRef();
  const [ui, setUi] = useState(undefined);
  const [lngSelected, setLngSelected] = useState("eng");

  useEffect(() => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://thvilela.com/src/neta/UnityLoader.js";
    script.onload = function () {
      setTimeout(() => {
        const unityInstance = window.UnityLoader.instantiate(
          "unityContainer",
          "https://thvilela.com/src/neta/1st-person-prueba.json",
          { onProgress: UnityProgress }
        );
        setUi(unityInstance);
        unityRef = unityInstance;
      }, 0);
    };

    document.getElementsByTagName("head")[0].appendChild(script);
    return () => {
      unityRef?.Quit(() => {
        console.log("done");
      });
    };
  }, []);

  const handleFullScreen = () => {
    ui.SetFullscreen(1);
  };

  return (
    <div className="container">
      <Header></Header>
      <div className="webgl-content">
        <div
          id="unityContainer"
          style={{ width: "960px", height: "600px" }}
        ></div>
        <div className="footer">
          <div className="webgl-logo"></div>
          <div className="fullscreen" onClick={handleFullScreen}></div>
          <div className="title" style={{ color: "white" }}>
            Pollen Glitch
          </div>
        </div>
      </div>
      <div className="gameContent">
        <div className="languageContainer">
          <div
            onClick={() => setLngSelected("eng")}
            className={lngSelected === "eng" && "lngActive"}
          >
            {lngSelected === "eng" ? "english" : "ingles"}
          </div>
          <div
            onClick={() => setLngSelected("spa")}
            className={lngSelected === "spa" && "lngActive"}
          >
            {lngSelected === "spa" ? "español" : "spanish"}
          </div>
        </div>
        {lngSelected === "eng" ? (
          <div className="textGame">
            <div className="gameContainer">
              <p className="title">Pollen Glitch</p>
              <p className="text">
                Pollen Glitch is a new universe where bees are flying and find
                new blossoming worlds looking for pollen.
              </p>
              <p className="text">
                Bees (our great pollinators) travel to another world in search
                of flowers to be able to help the plants reproduce and give rise
                to new seeds, flowers and fruits. These bees have traveled so
                far to another colorful dimension which has a middleman glitch
                between our current world and this new dimension.
              </p>
              <p className="text">
                In this tour you can observe this world as bees do and you can
                find other universes where you will find different types of
                flowers.
              </p>
              <p className="text">
                Enjoy the travel like a bee and dive into the glitch.
              </p>
              <p className="text">
                Pollen Glitch is a video game created in Unity by N3t4
                musicalized with binaural sounds.
              </p>
            </div>
            <div className="gameExplication">
              <p className="title">How to explore</p>
              <p>pulse ESC to get out the game</p>
              <p>
                use <span className="imgBg"></span> to use fullscreen
              </p>
              <p>move with your mouse and use: W A S D to navigate</p>
              {/* <p>
              If the game doesn't load, open the link:{" "}
              <a href="https://n3t4.digital/game" style={{ color: "white" }}>
                https://n3t4.digital/game
              </a>{" "}
              in incognito mode (Chrome) or use firefox
            </p> */}
            </div>
          </div>
        ) : (
          <div className="textGame">
            <div className="gameContainer">
              <p className="title">Pollen Glitch</p>
              <p className="text">
                Pollen Glitch es un nuevo universo donde las abejas vuelan y
                encuentran nuevos mundos florecientes en busca de polen.
              </p>
              <p className="text">
                Las abejas (nuestros grandes polinizadores) viajan a otro mundo
                en busca de flores para poder ayudar a las plantas a
                reproducirse y dar lugar a nuevas semillas, flores y frutos.
                Estas abejas han viajado a otra dimensión colorida que tiene un
                glitch (error) intermedio entre nuestro mundo actual y esta
                nueva dimensión.
              </p>
              <p className="text">
                En este tour podrás observar este mundo como lo hacen las abejas
                y podrás encontrar otros universos donde encontrarás diferentes
                tipos de flores.
              </p>
              <p className="text">
                Disfruta del viaje como una abeja y sumérgete en el glitch.
              </p>
              <p className="text">
                Pollen Glitch es un videojuego creado en Unity por N3t4
                musicalizado con sonidos binaurales.
              </p>
            </div>
            <div className="gameExplication">
              <p className="title">CÓMO EXPLORAR</p>
              <p>pulse ESC para salir del juego</p>
              <p>
                usar <span className="imgBg"></span> para usar la pantalla
                completa
              </p>
              <p>
                muévete con el mouse y usa las teclas: W, A, S, D para navegar
              </p>
              {/* <p>
              If the game doesn't load, open the link:{" "}
              <a href="https://n3t4.digital/game" style={{ color: "white" }}>
                https://n3t4.digital/game
              </a>{" "}
              in incognito mode (Chrome) or use firefox
            </p> */}
            </div>
          </div>
        )}

        <div className="gameExamplesImg">
          <a
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=w6nbqEOjSH8&feature=youtu.be"
            target="_blank"
            className="item"
          >
            <img src="imgs/game/gameExample1.png" alt="1"></img>
          </a>
          <a
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=w6nbqEOjSH8&feature=youtu.be"
            target="_blank"
            className="item"
          >
            <img src="imgs/game/gameExample2.png" alt="2"></img>
          </a>
          <a
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=w6nbqEOjSH8&feature=youtu.be"
            target="_blank"
            className="item"
          >
            <img src="imgs/game/gameExample3.png" alt="3"></img>
          </a>
          <a
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=w6nbqEOjSH8&feature=youtu.be"
            target="_blank"
            className="item"
          >
            <img src="imgs/game/gameExample4.png" alt="4"></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Game;
