import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useWindowMessure } from "../../hooks/useWindowMessure";
import "./index.scss";

function Work() {
  const [width, height] = useWindowMessure();
  const [messure, setMessure] = useState({ width, height });

  useEffect(() => {
    let newWidth = 0;
    let newHeight = 0;
    const widthPercent = 2.2;
    const heightPercent = 1.5;
    if (width <= height) {
      newWidth = height * widthPercent;
      newHeight = height * heightPercent;
    } else if (width > height * widthPercent) {
      const percent = (height * 100) / width;
      if (percent > 60) {
        newWidth = width;
        newHeight = height * heightPercent;
      } else {
        newWidth = width;
        newHeight = ((width * 60) / 100) * heightPercent;
      }
    } else {
      newWidth = width * heightPercent;
      newHeight = newWidth * 1.1 * heightPercent;
    }

    setMessure({
      width: newWidth,
      height: newHeight,
    });
  }, [width, height]);

  return (
    <div className="container">
      <div className="videoContainer">
        <iframe
          className="videoFrame"
          title="video"
          src="https://player.vimeo.com/video/494300089?muted=1&autoplay=1&loop=1&autopause=0&background=1&title=0&byline=0&portrait=0"
          style={{
            width: `${messure.width}px`,
            height: `${messure.height}px`,
          }}
          frameBorder="0"
        ></iframe>
      </div>
      <Header></Header>
      <div className="workContainer">
        <div className="workText">
          <p className="title">works</p>
        </div>
        <div className="workContent">
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://player.vimeo.com/video/494612261"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://player.vimeo.com/video/394650168"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://www.youtube.com/embed/Kyq19ZpULVg"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://www.youtube.com/embed/5ASaHDiauDY"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://www.youtube.com/embed/Dxr6Eh0rVr8"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://www.youtube.com/embed/l_3jGm6ctbY"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://www.youtube.com/embed/bQS6K3Ybddk"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://www.youtube.com/embed/KPKCsCknEjE"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://www.youtube.com/embed/HCWjD6cvnSY"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          <div className="workItem">
            <a className="item">
              <iframe
                title="ouTube video player"
                src="https://www.youtube.com/embed/KumJIsgFjTU"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "300px",
                }}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </a>
          </div>
          {/* <div className="workItem">
            <a
              rel="noreferrer"
              href="https://maca.aq.upm.es/quien/yannet-vilela/"
              target="_blank"
              className="item"
            >
              <iframe
                title="1"
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fn3t4n3t4%2Fvideos%2F1980092445406009%2F&show_text=false"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "100%",
                  height: "280px",
                }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </a>
          </div> */}
          <div className="workContentImg">
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/v2/workv21.jpg" alt="workv21"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/v2/workv27.png" alt="workv27"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div
                className="item"
                style={{ maxHeight: "250px", height: "auto", width: "auto" }}
              >
                <img src="imgs/works/v2/workv23.png" alt="workv23"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/v2/workv24.png" alt="workv24"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/v2/workv25.png" alt="workv25"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/v2/workv26.png" alt="workv26"></img>
              </div>
            </div>

            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/v2/workv22.jpg" alt="workv22"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/v2/workv28.png" alt="workv28"></img>
              </div>
            </div>

            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/lunario.jpg" alt="lunario"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/neta2.jpg" alt="neta2"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/visualVodka.jpg" alt="visualVodka"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work5.jpg" alt="work5"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work6.jpg" alt="work6"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work7.jpg" alt="work7"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work8.jpg" alt="work8"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work9.jpg" alt="work9"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work10.jpg" alt="work10"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work11.jpg" alt="work11"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work12.jpg" alt="work12"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work13.jpg" alt="work13"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work14.jpg" alt="work14"></img>
              </div>
            </div>
            <div className="workItemImg">
              <div className="item">
                <img src="imgs/works/work15.jpg" alt="work15"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
