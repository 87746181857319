import { useEffect, useState } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import Header from "../../components/Header";
import "./index.scss";

const url = "https://thvilela.com/src/newHuaca/";

const unityContext = new UnityContext({
  loaderUrl: url + "huaca_final.loader.js",
  dataUrl: url + "huaca_final.data",
  frameworkUrl: url + "huaca_final.framework.js",
  codeUrl: url + "huaca_final.wasm",
  streamingAssetsUrl: "StreamingAssets",
  companyName: "DefaultCompany",
  productName: "New Unity Project",
  productVersion: "0.1",
});

export default function Huaca() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [progression, setProgression] = useState(0);

  useEffect(function () {
    unityContext.on("loaded", function () {
      setIsLoaded(true);
    });
    unityContext.on("progress", function (progression) {
      setProgression(progression);
    });
    return function () {
      unityContext.removeEventListener("progress");
    };
  }, []);

  function handleOnClickFullscreen() {
    unityContext.setFullscreen(true);
  }

  return (
    <div className="container">
      <Header></Header>
      <div className="webgl-content">
        <div className="unityContainer">
          {!isLoaded ? (
            <div className="unityLoaderContainer">
              <div className="progressBarContainer">
                <div
                  className="progressBarFill"
                  style={{ width: `${Math.floor(progression * 100)}%` }}
                ></div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Unity unityContext={unityContext} className={"gameCanvas"} />
        </div>
        <div className="footer">
          <div className="webgl-logo"></div>
          <button
            className="fullscreen"
            onClick={handleOnClickFullscreen}
          ></button>
          <div className="title" style={{ color: "white" }}>
            RUMI Collage
          </div>
        </div>
      </div>
      <div className="gameContent">
        <div className="textGame">
          <div className="gameContainer">
            <p className="title">RUMI Collage</p>
            <p className="text">
              Esta obra es un collage digital en 3d de huacas encontradas en
              Perú e invita al usuario que visita el espacio a recorrer y
              encontrar distintos objetos de diferentes culturas del Perú.{" "}
            </p>
            <br />
            <p className="text">
              Año: 2021 <br />
              Técnica: 3d + fotogrametría + unity
              <br />
              Autor: Yannet Vilela Medina (N3T4)
            </p>
          </div>
          <div className="gameExplication">
            <p className="title">CÓMO EXPLORAR</p>
            <p>
              usar <span className="imgBg"></span> para usar la pantalla
              completa
            </p>
            <p>pulse ESC para salir de la pantalla completa</p>
            <p>Para moverte debes usar las teclas: W, A, S, D para navegar</p>
            {/* <p>
              If the game doesn't load, open the link:{" "}
              <a href="https://n3t4.digital/game" style={{ color: "white" }}>
                https://n3t4.digital/game
              </a>{" "}
              in incognito mode (Chrome) or use firefox
            </p> */}
          </div>
        </div>

        <div className="gameExamplesImg">
          <a className="item">
            <img src="imgs/huaca/huaca_1.png" alt="1"></img>
          </a>
          <a className="item">
            <img src="imgs/huaca/huaca_2.png" alt="2"></img>
          </a>
        </div>
      </div>
    </div>
  );
}
