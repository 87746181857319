import { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import UnityProgress from "./TemplateData/UnityProgress";
import "./index.scss";

function Game() {
  let unityRef = useRef();
  const [ui, setUi] = useState(undefined);

  useEffect(() => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://thvilela.com/src/hibri/UnityLoader.js";
    script.onload = function () {
      setTimeout(() => {
        const unityInstance = window.UnityLoader.instantiate(
          "unityContainer",
          "https://thvilela.com/src/hibri/hibernacion-jesperino.json",
          { onProgress: UnityProgress }
        );
        setUi(unityInstance);
        unityRef = unityInstance;
      }, 0);
    };

    document.getElementsByTagName("head")[0].appendChild(script);
    return () => {
      unityRef?.Quit(() => {
        console.log("done");
      });
    };
  }, []);

  const handleFullScreen = () => {
    ui.SetFullscreen(1);
  };

  return (
    <div className="container">
      {/* <Header></Header> */}
      <br></br>
      <br></br>
      <div className="webgl-content">
        <div
          id="unityContainer"
          style={{ width: "960px", height: "600px" }}
        ></div>
        <div className="footer">
          <div className="webgl-logo"></div>
          <div className="fullscreen" onClick={handleFullScreen}></div>
          <div className="title" style={{ color: "white" }}>
            Hibernación
          </div>
        </div>
      </div>
      <div className="gameContent">
        <div className="gameContainer">
          <p className="title">Hibernación</p>
          <p className="text">
            Hibernación refleja la expectativa e incertidumbre en el cual nos
            encontramos, esperando una respuesta sobre el nuevo mundo que nos
            espera.
          </p>
          <p className="text">
            - Modelo 3d sentado se puede apreciar mis herramientas de trabajo
            acumuladas sin uso desde inicio de la cuarentena.<br></br>- Modelo
            3d saliendo con mascarilla fuera de casa
          </p>
          <p className="text">
            Es un espacio creado con modelos 3d del artista que fueron creados
            usando la técnica de fotogrametría al inicio de la cuarentena. Está
            ambientado con sonidos binaurales creados en vcv rack una
            herramienta de software libre que emula sintetizadores.
          </p>
          <p className="text">
            Puedes realizar el recorrido como un avatar predeterminado ponte
            audífonos para disfrutar los sonidos.{" "}
          </p>
          <p className="text">Realizado por Jesper Frederiksen</p>
        </div>
        <div className="gameExplication">
          <p className="title">Como explorar:</p>
          <p>
            Utiliza w,a,s,d y el mouse para moverte. pulsa la barra espaciadora
            para saltar
          </p>
          <p>- Clic derecho para acercar la camara</p>
          <p>- Esc para salir del juego</p>
          <p>- F5 para actualizar</p>
        </div>
        <div className="gameExamplesImg">
          <a
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=Rr36HWKqsAI&feature=youtu.be&fbclid=IwAR1Ek9RoxAXRwuLbRtedyo-lG6pGhJVRqt69PDl6fcs8avbTLoqMVSVjRPo"
            target="_blank"
            className="item"
          >
            <img src="imgs/hibernation/jesper1.png" alt="1"></img>
          </a>
          <a
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=Rr36HWKqsAI&feature=youtu.be&fbclid=IwAR1Ek9RoxAXRwuLbRtedyo-lG6pGhJVRqt69PDl6fcs8avbTLoqMVSVjRPo"
            target="_blank"
            className="item"
          >
            <img src="imgs/hibernation/jesper2.png" alt="2"></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Game;
