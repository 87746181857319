import Home from "./pages/Home";
import About from "./pages/About";
import Exhibition from "./pages/Exhibition";
import WorkShop from "./pages/Workshop";
import Work from "./pages/Work";
import Game from "./pages/Game";
import Muki from "./pages/Muki";
import Huaca from "./pages/Huaca";
import Hibernation from "./pages/Hibernation";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/exhibitions">
          <Exhibition />
        </Route>
        <Route exact path="/workshops">
          <WorkShop />
        </Route>
        <Route exact path="/works">
          <Work />
        </Route>
        <Route exact path="/game">
          <Game />
        </Route>
        <Route exact path="/jesperino-muki">
          <Muki />
        </Route>
        <Route exact path="/rumi">
          <Huaca />
        </Route>
        <Route exact path="/hibernacionbyjesperino">
          <Hibernation />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
