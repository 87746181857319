import { useEffect, useState } from "react";
import { useWindowMessure } from "../../hooks/useWindowMessure";
import Header from "../../components/Header";
import "./index.scss";

function Home() {
  const [width, height] = useWindowMessure();
  const [messure, setMessure] = useState({ width, height });

  useEffect(() => {
    let newWidth = 0;
    let newHeight = 0;
    const widthPercent = 2.2;
    const heightPercent = 1.5;
    if (width <= height) {
      newWidth = height * widthPercent;
      newHeight = height * heightPercent;
    } else if (width > height * widthPercent) {
      const percent = (height * 100) / width;
      if (percent > 60) {
        newWidth = width;
        newHeight = height * heightPercent;
      } else {
        newWidth = width;
        newHeight = ((width * 60) / 100) * heightPercent;
      }
    } else {
      newWidth = width * heightPercent;
      newHeight = newWidth * 1.1 * heightPercent;
    }

    setMessure({
      width: newWidth,
      height: newHeight,
    });
  }, [width, height]);

  return (
    <div className="container">
      <div className="videoContainer">
        <iframe
          className="videoFrame"
          title="video"
          src="https://player.vimeo.com/video/494301127?muted=1&autoplay=1&loop=1&autopause=0&background=1&title=0&byline=0&portrait=0"
          style={{
            width: `${messure.width}px`,
            height: `${messure.height}px`,
          }}
          frameBorder="0"
        ></iframe>
      </div>
      <Header></Header>
    </div>
  );
}

export default Home;
