import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./index.scss";

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const handleBurgerButton = (evt) => {
    setActiveMenu(evt.currentTarget.checked);
  };
  return (
    <div className="content">
      <div className="headerContent">
        <div className="header">
          <Link to="/" className="Logo">
            N3T4
          </Link>
          <div id="menuToggle">
            <input type="checkbox" onChange={handleBurgerButton} />
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className={`menuFixed ${activeMenu ? "activeMenu" : ""}`}>
        <div className="navigation">
          <NavLink to="/about" activeClassName="active" className="linkItem">
            About
          </NavLink>
          <NavLink
            to="/exhibitions"
            activeClassName="active"
            className="linkItem"
          >
            Exhibitions
          </NavLink>
          <NavLink
            to="/workshops"
            activeClassName="active"
            className="linkItem"
          >
            Workshops
          </NavLink>
          <NavLink to="/works" activeClassName="active" className="linkItem">
            Works
          </NavLink>
          <NavLink to="/game" activeClassName="active" className="linkItem">
            Pollen Glitch
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
